@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html{
  padding: 0;
  margin: 0;
  font-size: 10px;
}
*{
  box-sizing: border-box;
}
body{
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  line-height: 1.3;
  color: #737373;
  background-color: #f5f5f5;
}

img{
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

small{
  font-size: 0.72em;
  display: block;
  line-height: 1;
}

.container{
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4rem;
}


header{
  background-color: #ff4848;
  height: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  justify-items: flex-start;
  
}
.logo img{
  display: inline-block;
  vertical-align: middle;
  width: 92px;
  height: 29px;
}
.instrument-list{
  display: -webkit-flex;
  display: flex;
  margin: 0 -0.5rem;
  padding: 1.6rem 0;
  position: relative;
}
.instrument-list .instrument-list-slider{
  height: 100%;
  width: 100%;
  
}
.instrument-card{
  padding: 0 0.5rem;
  /* width: 25%; */
  /* width: 100px; */
}
.instrument-card-inner{
  border-radius: 10px;
  background-color: #ffffff;
  padding: 1.5rem;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: none;
  cursor: pointer;
  height: 16.7rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.instrument-card-inner:hover{
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.08);
}
.instrument-card p{
  font-size: 2.2rem;
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.instrument-card p:first-child{
  margin-top: 0;
}
.instrument-card .card-top{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.instrument-card .card-top > div{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-flow: column;
          flex-flow: column;
}
.instrument-card .card-top .card-top-left{
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.instrument-card .card-top  p:last-child{
  padding: 0.5rem;
  margin: 0.5rem -0.5rem 0;
}
.instrument-card .card-top .card-top-right{
  text-align: right;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.instrument-card .arrow-wrapper img{
  width: 3rem;
}
.instrument-card .card-bottom{
  padding-top: 0.5rem;
  text-align: center;
}
.instrument-card .card-bottom img{
  width: 80%;
  margin: 0 auto;
  -webkit-filter: invert(41%) sepia(75%) saturate(3424%) hue-rotate(176deg) brightness(101%) contrast(93%);
          filter: invert(41%) sepia(75%) saturate(3424%) hue-rotate(176deg) brightness(101%) contrast(93%);
}

.card-buttons{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 -0.5rem;
}

.card-button{
  display: -webkit-flex;
  display: flex;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #58c914;
  text-decoration: none;
  border-radius: 5px;
  margin: 0 0.5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  transition: .2s ease-in-out;
  box-shadow: none;


}
.card-button:hover{
  box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.37);
}
.card-button:active,
.card-button:focus{
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.37)
}

.card-button:after{
  content: "";
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #fff;
    margin-left: .5em;
    margin-top: .2em;
}


/* Smaller Height */
.height-small .container{
  padding:0 0.5rem;
}
.height-small .instrument-list{
  margin: 0 -0.25rem;
  padding: .5rem 0;
}
.height-small  .instrument-list .instrument-list-slider{
  width: calc(100% - 27rem);
}

.height-small .instrument-list  .instrument-card{
  padding: 0 0.25rem;
}
.height-small .instrument-list .instrument-card-inner{
  padding: 1rem;
  height: 8rem;
}
.height-small .instrument-card .card-top{
  height: 100%;
}
.height-small .instrument-list  .instrument-card p{
  margin-top: 0;
}
.height-small .instrument-list  .instrument-card p:first-child{
  margin-top: 0;
}
.height-small .instrument-list .instrument-card.logo-card{
  width: auto;
}
.height-small .instrument-list .instrument-card.logo-card .instrument-card-inner{
  width: 13.6rem;
}
.height-small .instrument-list .instrument-card.logo-card .instrument-card-inner{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.height-small .instrument-list .instrument-card.link-card{
  padding-left: 0.3rem;
}
.height-small .instrument-list .instrument-card.link-card .instrument-card-inner .link-text{
  font-size: 2.2rem;
  -webkit-align-items: center;
          align-items: center;
  color: #0995dd;
  line-height: 1;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  height: 100%;
}
.height-small .instrument-list .instrument-card.link-card .link-text:after{
    content: "";
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #0995dd;
    margin-right: .5em;
    margin-top: .2em;
}

.height-small .slick-slider{
  position: static;
}
.height-small .slick-prev{
  display: none !important;
}
.height-small .slick-next{
  border-radius: 10px;
  background-color: #ffffff !important;
  height: 8rem;
  line-height: 8rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: none;
  right: 0.2rem;
  padding: 0.2em;
  width: 2.8rem;
}

.height-small .slick-next:hover{
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.08);
}
.height-small .slick-next:before{
  content: "»";
  position: relative;
  bottom: -0.2em;
  font-size: 2.5rem;
}

@media (min-width: 768px){
  .height-small .card-bottom{
    display: none;
  }
}


.slick-prev:before, .slick-next:before{
  color: #161212 !important;
}
.instrument-price{
  display: block;
  padding: 0.2rem;
  margin-left: -0.2rem;
}
.green{
  -webkit-animation : greenbg 1s ease-in-out;
          animation : greenbg 1s ease-in-out;
  }
.red{
  -webkit-animation : redbg 1s ease-in-out;
          animation : redbg 1s ease-in-out;
  }

@-webkit-keyframes greenbg {
  0% {background-color: white; color: #737373;  }
  50% {background-color: #58c914; color: white;}

  100% {background-color: white; color: #737373;}
  }

@keyframes greenbg {
  0% {background-color: white; color: #737373;  }
  50% {background-color: #58c914; color: white;}

  100% {background-color: white; color: #737373;}
  }
@-webkit-keyframes redbg {
  0% {background-color: white; color: #737373;}
  50% {background-color: #ff4848; color: white;}
  
  100% {background-color: white; color: #737373;}
}
@keyframes redbg {
  0% {background-color: white; color: #737373;}
  50% {background-color: #ff4848; color: white;}
  
  100% {background-color: white; color: #737373;}
}
@media (max-width: 919px) and (min-width: 768px){
  html, body{
    font-size: 8px;
  }
  main{
    height: 210px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .height-small{
    height: 90px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
}
@media (min-width: 768px){
  .mobile-widget{
    display: none !important;
  }
  .height-small .slick-next{
    display: none !important;
  }
  .instrument-list .instrument-list-slider .slick-list{
    padding: .5rem 0;
    margin: -0.5rem 0;
  }
}
@media (max-width: 767px){
  body, html{
    height: 600px;
    overflow: hidden;
  }
  .container{
    padding: 0;
    max-width: 300px;
  }
  .desktop-widget{
    display: none !important;
  }
  header{
    text-align: center;
  }
  .slide-container{
    padding: 0 0.25rem;
  }

  .card-buttons{
    margin: 0 -0.25rem;
  }
  .card-button{
    font-size: 1.4rem;
    padding: 0.75rem 0.85rem;
    margin: 0 0.25rem;
  }
  .card-button:after {
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 4px solid #fff;
  }
 
}


/* Mobile Slider */
.mobile-widget .slide-row  .slide-row-inner{
  display: -webkit-flex;
  display: flex;
}
.mobile-widget .slide-col{
  width: 50%;
}
.mobile-widget .slick-prev{
  display: none !important;
}
.mobile-widget .slick-next {
  right: 50%;
  top: 100%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  border-radius: 10px;
  background-color: #ffffff !important;
  width: 6rem;
  line-height: 3.5rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: none;
  padding: 0.2em;
  height: 3.5rem;
  margin-top: 1.8rem;
}
.mobile-widget .slick-next:before {
  content: "»";
  position: relative;
  font-size: 2.5rem;
  display: block;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 0.5rem;
  left: 0.4rem;
}
.mobile-widget .slick-next:hover{
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.08);
}
.mobile-widget .instrument-card{
  PADDING: 0.25rem;
}
.mobile-widget .instrument-list{
  margin: 0 -0.25rem;
  padding: 0.5rem 0;
}
.mobile-widget .instrument-card p{
  font-size: 1.8rem;
}
.mobile-widget .instrument-card-inner{
  height: 15rem;
  padding: 2.25rem 0.75rem;
}
.mobile-widget .instrument-card .card-bottom img{
  width: 90%;
}
 

.platform-url:not(.card-button){
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
header, .instrument-card-inner{
  position: relative;
}
